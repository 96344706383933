<template>
  <div>
    <div class="row col-12 page-head">
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb page-head-nav">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'dashboard' }">Home</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link-active :to="{ name: 'customer-support-agents-list' }"
              >Sales Representatives</router-link-active
            >
          </li>
        </ol>
      </nav>
    </div>

    <div class="card">
      <div class="card-body">
        <SalesRepresentativeUserTable />
      </div>
    </div>
  </div>
</template>

<script>
import SalesRepresentativeUserTable from "@/components/Users/SalesRepresentativeUserTable.vue";

export default {
  components: { SalesRepresentativeUserTable },
};
</script>

<style></style>
